import axios from 'axios';
import { apiAuth as mainStoreApi } from '../store/useMainStore';

// Creating an instance of axios client with default configuration
const axiosClient = axios.create();

// Setting default headers for all requests sent by this client
axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
};

// Setting the default timeout for all requests sent by this client to 20 seconds
axiosClient.defaults.timeout = 20000;

// Setting withCredentials to true for all requests sent by this client
axiosClient.defaults.withCredentials = true;

axiosClient.interceptors.response.use(function (response) {
    //Dispatch any action on success
    return response;
}, function (error) {
    const error_code_unauthorized = 401;
    if(error.response.status === error_code_unauthorized){
        //handling unauthrized status
        mainStoreApi.setIsAuthorized(false);
        return Promise.reject(error);
    } 
    return Promise.reject(error);
});


// Wrapper functions for making HTTP requests using the axios client instance
// Each function returns a Promise that resolves to the response data or rejects with an error
export function getRequest(URL) {
    return axiosClient.get(`${URL}`).then(response => response);
}
  
export function postRequest(URL, payload) {
    return axiosClient.post(`${URL}`, payload).then(response => response);
}
  
export function putRequest(URL, payload) {
    return axiosClient.put(`${URL}`, payload).then(response => response);
}
  
export function deleteRequest(URL) {
    return axiosClient.delete(`${URL}`).then(response => response);
}
